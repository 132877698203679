import { match } from 'ts-pattern';
import { list } from '../../utils/list';
import { Feedback } from '../Feedback';

interface SubmittabilityFeedbackProps {
  isCore: boolean;
  isEligible: boolean;
  meetsRoundRequirements: boolean;
  meetsTourDateRequirement: boolean;
  meetsOrionRoundRequirement: boolean;
  hasIneligibleTourDates: boolean;
  hasIneligibleFundingRequests: boolean;
  exceedsYearlyTotalForFundingRequests: boolean;
  exceedsYearlyNumberOfTourDatesByRegion: string[];
  roundTitle: string | undefined;
}

export function SubmittabilityFeedback({
  isCore,
  isEligible,
  meetsRoundRequirements,
  meetsTourDateRequirement,
  meetsOrionRoundRequirement,
  hasIneligibleTourDates,
  hasIneligibleFundingRequests,
  exceedsYearlyTotalForFundingRequests,
  exceedsYearlyNumberOfTourDatesByRegion,
  roundTitle,
}: SubmittabilityFeedbackProps) {
  const warnings = (
    [
      [isEligible === false, 'You are NOT eligible for this round.'],
      [
        meetsTourDateRequirement === false,
        'Please add at least 3 confirmed eligible dates in one of the following territories: Canada, the US, or International (non-US) territories.',
      ],
      [
        meetsOrionRoundRequirement === false,
        roundTitle === undefined
          ? 'Applications for the Orion Program are not being accepted.'
          : `Applications for the Orion Program are not being accepted during Round #${roundTitle}.`,
      ],
      [hasIneligibleTourDates, 'Please remove all ineligible tour dates.'],
      [
        hasIneligibleFundingRequests,
        isCore
          ? 'Please remove all ineligible digital content requests.'
          : 'Please remove all ineligible funding requests.',
      ],
      [
        exceedsYearlyNumberOfTourDatesByRegion.length > 0,
        `Please remove tour dates in the ${list(
          exceedsYearlyNumberOfTourDatesByRegion,
          'region',
          'regions'
        )} to fit within yearly limits.`,
      ],
      [
        exceedsYearlyTotalForFundingRequests,
        isCore
          ? 'Please alter digital content requests to fit within remaining yearly budget.'
          : 'Please alter funding requests to fit within remaining yearly budget.',
      ],
    ] satisfies Array<[boolean, string]>
  )
    .filter(([show]) => show)
    .map(([, text]) => text);

  return meetsRoundRequirements ? (
    <Feedback
      icon="checkCircle"
      type="positive"
      title="Your application is ready to submit."
    >
      Please review the details and submit when ready. Once your application is
      submitted, you will not be able to make changes.
    </Feedback>
  ) : (
    <Feedback
      icon="alertTriangle"
      type="warning"
      title="Your application is NOT yet ready to submit."
    >
      {match(warnings.length)
        .with(0, () => '')
        .with(1, () => warnings[0])
        .otherwise(() => (
          <ul>
            {warnings.map((warning) => (
              <li key={warning}>{warning}</li>
            ))}
          </ul>
        ))}
    </Feedback>
  );
}
