import { Context } from '../../machines/Application/ApplicationMachine';
import { ApplicationStage, ApplicationType } from '../../graphql/operations';
import { RequirementCheck } from './RequirementCheck';
import { pluralize } from '../../utils/pluralize';

import styles from './ApplicationRequirements.module.scss';

interface ApplicationRequirementsProps {
  context: Context;
}

export function ApplicationRequirements({
  context,
}: ApplicationRequirementsProps) {
  const {
    applicationType,
    attachments,
    submittedAt,
    stage,
    contacts,
    primaryContact,
    totalNumberOfTourDates,
    totalNumberOfFundingRequests,
    meetsRoundRequirements,
    meetsTourDateRequirement,
    meetsFundingRequestRequirement,
    meetsStatementRequirement,
    tourDates,
    tourSummary,
    approvedFundingRequestsWithoutExpense,
  } = context;

  const isApplicationReady = meetsRoundRequirements || Boolean(submittedAt);

  const attachmentCount = attachments.length;
  const extraContactsCount = contacts.filter(
    ({ email }) => email !== primaryContact?.email
  ).length;
  const confirmedDateCount = tourDates.filter(
    ({ confirmed }) => confirmed
  ).length;
  const tentativeDateCount = totalNumberOfTourDates - confirmedDateCount;

  return (
    <div className={styles.container}>
      {applicationType === ApplicationType.Orion && (
        <>
          <RequirementCheck
            icon="document"
            checkedLabel="Proposal added"
            uncheckedLabel="No proposal added"
            isChecked={meetsStatementRequirement}
          />
          {!isApplicationReady && (
            <RequirementCheck
              icon="dollarSign"
              checkedLabel={pluralize(
                totalNumberOfFundingRequests,
                'funding request'
              )}
              uncheckedLabel="No funding requests"
              isChecked={meetsFundingRequestRequirement}
            />
          )}
        </>
      )}
      {applicationType === ApplicationType.Core && !isApplicationReady && (
        <>
          <RequirementCheck
            icon="calendar"
            checkedLabel={pluralize(
              totalNumberOfTourDates,
              'tour date added in total',
              'date'
            )}
            uncheckedLabel={
              totalNumberOfTourDates
                ? pluralize(
                    totalNumberOfTourDates,
                    'tour date added in total',
                    'date'
                  )
                : 'No tour dates added'
            }
            details={
              totalNumberOfTourDates > 0
                ? `(${confirmedDateCount} Confirmed, ${tentativeDateCount} Tentative)`
                : ''
            }
            isChecked={meetsTourDateRequirement}
          />
          <RequirementCheck
            icon="radio"
            checkedLabel={pluralize(
              totalNumberOfFundingRequests,
              'digital content request'
            )}
            uncheckedLabel="No digital content requests"
            isChecked={meetsFundingRequestRequirement}
            isOptional={true}
          />
        </>
      )}
      <RequirementCheck
        icon="mail"
        checkedLabel={
          primaryContact
            ? `${extraContactsCount ? 'Contacts' : 'Contact'}: ${
                primaryContact.name
              } <${primaryContact.email}>${
                extraContactsCount ? ` + ${extraContactsCount} additional` : ''
              }`
            : ''
        }
        uncheckedLabel="No contacts"
        isChecked={primaryContact !== undefined}
      />
      <RequirementCheck
        icon="folder"
        checkedLabel={pluralize(attachmentCount, 'attachment')}
        uncheckedLabel="No attachments"
        isChecked={attachmentCount > 0}
        isOptional={true}
      />
      {submittedAt &&
        stage !== ApplicationStage.ContractAndDirectDepositPending && (
          <>
            {applicationType === ApplicationType.Core && (
              <RequirementCheck
                icon="document"
                checkedLabel="Tour summary uploaded"
                uncheckedLabel="No tour summary uploaded"
                isChecked={Boolean(tourSummary)}
              />
            )}
            {totalNumberOfFundingRequests > 0 && (
              <RequirementCheck
                icon="document"
                checkedLabel={`${
                  applicationType === ApplicationType.Core
                    ? 'Digital content'
                    : 'Funding request'
                } expenses added`}
                uncheckedLabel={`${approvedFundingRequestsWithoutExpense} ${
                  applicationType === ApplicationType.Core
                    ? `digital content expense${
                        approvedFundingRequestsWithoutExpense > 1 ? 's' : ''
                      }`
                    : `funding request${
                        approvedFundingRequestsWithoutExpense > 1 ? 's' : ''
                      }`
                } not yet confirmed`}
                isChecked={approvedFundingRequestsWithoutExpense === 0}
              />
            )}
          </>
        )}
    </div>
  );
}
