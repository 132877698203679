import { useAuth } from '../components/AuthProvider';

export function useIsAdmin(includeAuditorAsAdmin = true) {
  const { roles } = useAuth();
  // Also conditionally include auditor role here because we want them to have the
  // admin version of the site.
  return (
    roles.includes('administrator') ||
    (includeAuditorAsAdmin && roles.includes('auditor'))
  );
}
