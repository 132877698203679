import { match, P } from 'ts-pattern';
import { Block } from '../Block';
import { SummaryTable } from '../Tables/SummaryTable';
import {
  ApplicationStage,
  ApplicationStatus,
  ApplicationType,
  FundingRoundStatusType,
} from '../../graphql/operations';
import {
  ApplicationMachineSender,
  ApplicationMachineState,
} from '../../machines/Application/ApplicationMachine';
import { ApplicationReviewBlockFooter } from './ApplicationReviewBlockFooter';
import { SubmittabilityFeedback } from './SubmittabilityFeedback';
import { ClaimsSubmittabilityFeedback } from './ClaimsSubmittabilityFeedback';
import { SubmissionDates } from './SubmissionDates';
import { ApplicationRequirements } from './ApplicationRequirements';
import { ErrorFeedback } from '../ErrorFeedback';
import { Payment } from '../../schemas/paymentSchema';
import { isReviewStage } from '../../utils/applicationStages';

import styles from './ApplicationReviewBlockView.module.scss';

interface ApplicationReviewBlockProps {
  state: ApplicationMachineState;
  send: ApplicationMachineSender;
}

type Mode = 'review' | 'summary';

export function ApplicationReviewBlockView({
  state,
  send,
}: ApplicationReviewBlockProps) {
  const {
    currentOrNextRound,
    artist: { isEligible },
    isSubmittable,
    isClaimsSubmittable,
    status,
    applicationType,
    stage,
    submittedAt,
    claimsSubmittedAt,
    claimsResubmittedAt,
    eligibilityDeadline,
    applicationRound,
    meetsRoundRequirements,
    meetsTourDateRequirement,
    meetsOrionRoundRequirement,
    meetsClaimsTourSummaryRequirement,
    meetsClaimsExpenseRequirement,
    meetsClaimsTourDateRequirement,
    meetsClaimsFundingRequestRequirement,
    exceedsYearlyTotalForFundingRequests,
    exceedsYearlyNumberOfTourDatesByRegion,
    requestedTotalForTourDates,
    requestedTotalForFundingRequests,
    totalNumberOfTourDates,
    totalNumberOfIneligibleTourDates,
    totalNumberOfFundingRequests,
    totalNumberOfIneligibleFundingRequests,
    totalNumberOfApprovedTourDates,
    totalNumberOfApprovedFundingRequests,
    totalNumberOfClaimedTourDates,
    totalNumberOfClaimedFundingRequests,
    approvedTotalForTourDates,
    approvedTotalForFundingRequests,
    claimedTotalForTourDates,
    claimedTotalForFundingRequests,
    directDepositAccounts,
  } = state.context;

  const isOpen =
    currentOrNextRound !== null &&
    currentOrNextRound.status === FundingRoundStatusType.OpenForApplications;

  const mode: Mode = isReviewStage(stage) ? 'review' : 'summary';

  const submissionIsEnabled = match({
    isOpen,
    stage,
    isSubmittable,
    isClaimsSubmittable,
  })
    .with(
      {
        isOpen: true,
        isSubmittable: true,
        stage: ApplicationStage.Unsubmitted,
      },
      () => true
    )
    .with(
      {
        isClaimsSubmittable: true,
        stage: P.union(
          ApplicationStage.ContractAndDirectDepositUploaded,
          ApplicationStage.ClaimsReopened
        ),
      },
      () => true
    )
    .otherwise(() => false);

  const allPayments = directDepositAccounts.reduce<Payment[]>(
    (acc, account) => {
      return [...acc, ...account.payments];
    },
    []
  );

  return (
    <div>
      <Block
        id="summary"
        headerText={match([mode, stage === ApplicationStage.ClaimsReopened])
          .with(['summary', P.any], () => 'Summary')
          .with(['review', false], () => 'Review & Submit')
          .with(['review', true], () => 'Review & Resubmit')
          .exhaustive()}
        iconName="editPencil"
        footer={
          mode === 'review' && (
            <ApplicationReviewBlockFooter
              isConfirmed={state.matches('form.review.confirmed')}
              context={state.context}
              toggleConfirmation={() => {
                send({ type: 'TOGGLE_CONFIRMATION' });
              }}
              submitApplication={() => {
                send('SUBMIT_APPLICATION');
              }}
              submitClaims={() => {
                send('SUBMIT_CLAIMS');
              }}
              resubmitClaims={() => {
                send('RESUBMIT_CLAIMS');
              }}
            />
          )
        }
        footerClassName={submissionIsEnabled ? styles.footer : ''}
      >
        {stage === ApplicationStage.Unsubmitted && (
          <SubmittabilityFeedback
            roundTitle={currentOrNextRound?.title}
            isCore={applicationType === ApplicationType.Core}
            isEligible={isEligible}
            meetsRoundRequirements={meetsRoundRequirements}
            meetsTourDateRequirement={meetsTourDateRequirement}
            meetsOrionRoundRequirement={meetsOrionRoundRequirement}
            hasIneligibleTourDates={totalNumberOfIneligibleTourDates > 0}
            hasIneligibleFundingRequests={
              totalNumberOfIneligibleFundingRequests > 0
            }
            exceedsYearlyTotalForFundingRequests={
              exceedsYearlyTotalForFundingRequests
            }
            exceedsYearlyNumberOfTourDatesByRegion={
              exceedsYearlyNumberOfTourDatesByRegion
            }
          />
        )}
        {[
          ApplicationStage.ContractAndDirectDepositUploaded,
          ApplicationStage.ClaimsReopened,
        ].includes(stage) && (
          <ClaimsSubmittabilityFeedback
            isCore={applicationType === ApplicationType.Core}
            isClaimsSubmittable={isClaimsSubmittable}
            meetsClaimsTourSummaryRequirement={
              meetsClaimsTourSummaryRequirement
            }
            meetsClaimsExpenseRequirement={meetsClaimsExpenseRequirement}
            meetsClaimsTourDateRequirement={meetsClaimsTourDateRequirement}
            meetsClaimsFundingRequestRequirement={
              meetsClaimsFundingRequestRequirement
            }
            isResubmission={stage === ApplicationStage.ClaimsReopened}
          />
        )}
        <SubmissionDates
          applicationType={applicationType}
          currentRound={isOpen ? currentOrNextRound : null}
          submittedAt={submittedAt}
          claimsSubmittedAt={claimsSubmittedAt}
          claimsResubmittedAt={claimsResubmittedAt}
          roundTitle={applicationRound?.title}
          eligibilityDeadline={eligibilityDeadline}
        />
        <ApplicationRequirements context={state.context} />
        {(meetsRoundRequirements || submittedAt) && (
          <SummaryTable
            applicationType={applicationType}
            tourDateCount={totalNumberOfTourDates}
            fundingRequestCount={totalNumberOfFundingRequests}
            tourDateTotal={requestedTotalForTourDates}
            fundingRequestTotal={requestedTotalForFundingRequests}
            showApprovedRow={status === ApplicationStatus.Approved}
            approvedTourDateCount={totalNumberOfApprovedTourDates}
            approvedFundingRequestCount={totalNumberOfApprovedFundingRequests}
            approvedTourDateTotal={approvedTotalForTourDates}
            approvedFundingRequestTotal={approvedTotalForFundingRequests}
            showClaimedRow={[
              ApplicationStage.ClaimsSubmitted,
              ApplicationStage.ClaimsResubmitted,
              ApplicationStage.Completed,
            ].includes(stage)}
            claimedTourDateCount={totalNumberOfClaimedTourDates}
            claimedFundingRequestCount={totalNumberOfClaimedFundingRequests}
            claimedTourDateTotal={claimedTotalForTourDates}
            claimedFundingRequestTotal={claimedTotalForFundingRequests}
            claimsSubmittedAt={claimsSubmittedAt}
            payments={allPayments}
          />
        )}
        {state.matches('form.review.confirmed.error') && <ErrorFeedback />}
      </Block>
    </div>
  );
}
