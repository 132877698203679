import { z } from 'zod';

export const roleSchema = z.union([
  z.literal('administrator'),
  z.literal('account'),
  z.literal('board'),
  z.literal('auditor'),
]);

export const rolesSchema = z.array(roleSchema);

export type Role = z.infer<typeof roleSchema>;

export type Roles = z.infer<typeof rolesSchema>;
