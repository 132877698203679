import { match } from 'ts-pattern';
import { Roles } from '../schemas/role';
import { MinimalArtist } from '../schemas/minimalArtistSchema';

export function getLandingPagePath(artists: MinimalArtist[], roles: Roles) {
  return match({
    artists,
    isAdmin: roles.includes('administrator'),
    isAuditor: roles.includes('auditor'),
    isBoard: roles.includes('board'),
  })
    .with({ isAdmin: true }, { isAuditor: true }, () => '/admin')
    .when(
      ({ artists }) => artists.length === 1,
      ({ artists }) => `/artist/${artists[0].id}`
    )
    .when(
      ({ isBoard, artists }) => isBoard && artists.length === 0,
      () => '/board-meeting'
    )
    .otherwise(() => `/artists`);
}
