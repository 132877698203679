import { Link } from 'react-router-dom';
import { Icon, IconName } from './Icon';
import { match } from 'ts-pattern';
import { useAuth } from './AuthProvider';

import styles from './HeaderAccountLinks.module.scss';

interface HeaderAccountLinksProps {
  onClick?: () => void;
}

export function HeaderAccountLinks({ onClick }: HeaderAccountLinksProps) {
  const { id, roles, artists, isSignedIn } = useAuth();

  if (!isSignedIn) {
    return null;
  }

  const isAdmin = roles.includes('administrator');
  const isAuditor = roles.includes('auditor');
  const isBoard = roles.includes('board');
  const artistCount = artists.length;

  const links = match<
    {
      isAdmin: boolean;
      isAuditor: boolean;
      isBoard: boolean;
      artistCount: number;
    },
    { to: string; icon: IconName; label: string }[]
  >({ isAdmin, isAuditor, isBoard, artistCount })
    .with({ isAdmin: true }, { isAuditor: true }, () => [
      { to: '/admin', icon: 'dashboard', label: 'Admin Dashboard' },
      { to: '/admin/artists', icon: 'star', label: 'All Artists' },
    ])
    .with({ isBoard: true }, () => [
      ...(artistCount > 0
        ? [{ to: '/artists', icon: 'star' as IconName, label: 'All Artists' }]
        : []),
      { to: '/board-meeting', icon: 'dashboard', label: 'Board Meeting' },
    ])
    .with({ artistCount: 1 }, () => [
      {
        to: `/artist/${artists[0].id}`,
        icon: 'dashboard',
        label: artists[0].name,
      },
    ])
    .otherwise(() => [{ to: '/artists', icon: 'star', label: 'All Artists' }])
    .concat({ to: `/account/${id}`, icon: 'user', label: 'Account' });

  return (
    <div className={styles.container}>
      {links.map(({ to, icon, label }) => (
        <Link key={label} to={to} className={styles.button} onClick={onClick}>
          <Icon
            width={24}
            height={24}
            name={icon}
            label={label}
            labelClassName={styles.buttonLabel}
          />
        </Link>
      ))}
    </div>
  );
}
